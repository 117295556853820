import { useState } from "react";
import { confirmSignIn, signIn } from "aws-amplify/auth";
import Login from "./components/Login";
import ResetPassword from "./components/ResetPassword";

const SRPLogin = ({ districtId, onSRPLogin }) => {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [errorType, setErrorType] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [isResetPassword, setIsResetPassword] = useState(false);

	const handleResetPasswordUpdate = async password => {
		try {
			await confirmSignIn({ challengeResponse: password });
			onSRPLogin();
		} catch (err) {
			setErrorType(err.name);
		}
	};

	const handleSRPLogin = async () => {
		const userLogin = `${districtId}__${username}`;
		try {
			const { nextStep } = await signIn({ username: userLogin, password });
			switch (nextStep.signInStep) {
				case "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED":
					setIsResetPassword(true);
					break;
				case "DONE":
					onSRPLogin();
					break;
				default:
					throw new Error("ReceivedUnexpectedChallenge");
			}
		} catch (err) {
			switch (err.name) {
				case "UserLambdaValidationException":
					setErrorMessage(
						"Your district uses SSO; please sign in using your school's portal"
					);
					break;
				case "NotAuthorizedException":
				case "UserNotFoundException":
					setErrorMessage("Incorrect user name or password, try again");
					break;
				default:
					setErrorMessage("An error has occurred, please try again");
			}
			setUsername("");
			setPassword("");
		}
	};

	return !isResetPassword ? (
		<Login
			setLogin={setUsername}
			login={username}
			setPassword={setPassword}
			password={password}
			onLogin={handleSRPLogin}
			errorMessage={errorMessage}
		/>
	) : (
		<ResetPassword
			onUpdate={handleResetPasswordUpdate}
			errorMessage={errorMessage}
			errorType={errorType}
		/>
	);
};

export default SRPLogin;
