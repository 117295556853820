import { Box, Link, Typography } from "@ellevation/backpack";
import React from "react";
import useNavigation from "_hooks/useNavigation";
import characterUnassigned from "img/characterUnassigned.svg";
import characterAssigned from "img/characterAssigned.svg";
import characterCompleted from "img/characterCompleted.svg";

export const StatusCard = ({
	isLearnPage,
	assignedCount,
	completedCount,
	classroomId
}) => {
	const { goReviewClassroom, goLearnClassroom } = useNavigation();

	const getCharacterImage = () => {
		if (completedCount > 0) {
			return characterCompleted;
		}
		if (assignedCount > 0) {
			return characterAssigned;
		}
		return characterUnassigned;
	};

	const CompletedPrimers = isLearnPage ? Link : "strong";

	return (
		<Box
			sx={{
				bgcolor: "rgba(93, 183, 219, 0.15)",
				borderRadius: 2,
				display: "inline-flex",
				alignItems: "center"
			}}>
			<Box
				sx={{
					display: { xs: "none", md: "block" },
					minHeight: 269,
					minWidth: 269,
					background: `url(${getCharacterImage()}) center/contain no-repeat`
				}}
			/>
			<Box sx={{ m: 5 }}>
				<Typography
					sx={{
						fontSize: "2.25rem",
						lineHeight: 1.2,
						".completed": {
							fontSize: "2.5rem",
							fontFamily: "bold",
							whiteSpace: "nowrap"
						}
					}}>
					{completedCount > 0 && (
						<>
							Nice work! You have completed{" "}
							<CompletedPrimers
								className="completed"
								onClick={
									isLearnPage ? () => goReviewClassroom(classroomId) : null
								}>
								{completedCount} {completedCount === 1 ? "Primer" : "Primers"}
							</CompletedPrimers>
							.
						</>
					)}
					{completedCount === 0 && assignedCount > 0 && (
						<>You have not completed any Primers yet.</>
					)}
					{completedCount === 0 && assignedCount === 0 && (
						<>You do not have any Primers.</>
					)}
				</Typography>
				<Typography
					sx={{
						fontSize: "1.5rem",
						pt: "1em",
						pr: 4,
						lineHeight: 1.25
					}}>
					{isLearnPage && completedCount > 0 && assignedCount > 0 && (
						<>
							Keep going! You have {assignedCount}{" "}
							{assignedCount === 1 ? "Primer" : "Primers"} assigned to you
							below.
						</>
					)}
					{isLearnPage && completedCount > 0 && assignedCount === 0 && (
						<>
							You do not have any more Primers. Come back later, or{" "}
							<Link
								onClick={() => goReviewClassroom(classroomId)}
								variant="inherit"
								sx={{ fontFamily: "bold" }}>
								review your completed Primers
							</Link>
							.
						</>
					)}
					{isLearnPage && completedCount === 0 && assignedCount > 0 && (
						<>Check out your new Primers below.</>
					)}
					{isLearnPage && completedCount === 0 && assignedCount === 0 && (
						<>Come back later once you have Primers assigned to you.</>
					)}
					{!isLearnPage && completedCount > 0 && (
						<>
							Review your completed Primers below.{" "}
							{assignedCount > 0 && (
								<>
									You can also check out your{" "}
									<Link
										onClick={() => goLearnClassroom(classroomId)}
										variant="inherit"
										sx={{ fontFamily: "bold" }}>
										new Primers here
									</Link>
									.
								</>
							)}
						</>
					)}
					{!isLearnPage && completedCount === 0 && (
						<>
							{assignedCount > 0 && (
								<>
									Check out your{" "}
									<Link
										onClick={() => goLearnClassroom(classroomId)}
										variant="inherit"
										sx={{ fontFamily: "bold" }}>
										new Primers
									</Link>
									.
								</>
							)}{" "}
							When you complete a Primer, you will see it here.
						</>
					)}
				</Typography>
			</Box>
		</Box>
	);
};
