import makeStyles from "@mui/styles/makeStyles";
import studentTheme from "themes/StudentTheme";

const useLandingPageStyles = makeStyles({
	root: {
		backgroundColor: studentTheme.colors.whitesmokeGray,
		minHeight: `calc(100vh - 58px)`,
		display: "flex",
		flexDirection: "column"
	},
	topContent: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		paddingTop: "6.25rem",
		flex: 1
	},
	box: {
		padding: "1.25rem",
		margin: "1.875rem",
		backgroundColor: studentTheme.colors.white,
		border: `1px solid ${studentTheme.colors.mediumGray}`,
		borderRadius: "0.25rem",
		display: "flex",
		flexDirection: "column"
	},
	header: {
		padding: "0.625rem",
		borderBottom: `0.03125rem solid ${studentTheme.colors.mediumGray}`
	},
	body: {
		margin: "0.625rem",
		display: "flex"
	},
	image: {
		margin: "0.3125rem",
		height: "12.5rem"
	},
	content: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		margin: "0.625rem"
	},
	pOverride: {
		margin: 0
	},
	link: {
		color: studentTheme.colors.primaryColor
	}
});
export default useLandingPageStyles;
