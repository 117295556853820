import makeStyles from "@mui/styles/makeStyles";
import studentTheme from "themes/StudentTheme";

const useUserMenuItemStyles = makeStyles({
	ersMenuText: {
		display: "flex"
	},
	ersUserInfo: {
		display: "block",
		fontSize: "1rem",
		lineHeight: "2rem",
		marginRight: "0.5rem",
		//overflow: 'hidden',
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		maxWidth: "240px",
		textAlign: "left",
		overflowX: "hidden",
		overflowY: "hidden"

		// @media screen and (min-width: 48.4375rem) {
		//     display: block;
		//     max-width: rem-calc(240);
		// }
	},
	ersMenuAvatar: {
		borderRadius: "1rem",
		minWidth: "2rem",
		height: "2rem",
		fontSize: "0.875rem",
		lineHeight: "2rem"
	},
	ersUserAvatar: {
		display: "inline-block",
		textAlign: "center",
		backgroundColor: studentTheme.colors.mediumGray
	},
	ersUserMenu: {
		"& a": {
			padding: "0.6875rem 0.5rem ",
			textDecoration: "none"
		},
		"&:hover": {
			backgroundColor: studentTheme.colors.navMenuHover
		}
	},
	ersUserMenuHover: {
		backgroundColor: studentTheme.colors.navMenuHover
	},
	popoverPaper: {
		boxShadow: "none",
		border: `1px solid ${studentTheme.colors.mediumGray}`
	}
});

export default useUserMenuItemStyles;
