import studentTheme from "themes/StudentTheme";
import logo from "img/nav_logo.png";

const TopNavStyles = {
	ersContainer: {
		display: "block",
		paddingLeft: "0.5rem",
		paddingRight: "0.5rem",
		backgroundColor: studentTheme.colors.whitesmokeGray,
		fontFamily: studentTheme.fonts.primaryFont
	},
	ersTopBar: {
		"&::before, &::after": {
			content: "close-quote",
			display: "table"
		},
		"&::after": {
			clear: "both"
		},
		display: "block",
		boxSizing: "border-box"
	},
	ersTopBarSection: {
		witdth: "auto"
	},

	ersHome: {
		"& > a": {
			paddingTop: "0.375rem",
			paddingBottom: "0.375rem",
			paddingLeft: "1rem",
			paddingRight: "1rem"
		}
	},
	ersLogo: {
		display: "block",
		backgroundImage: `url(${logo})`,
		backgroundRepeat: "no-repeat",
		backgroundPosition: "0 50%",
		textIndent: "-9999px",
		width: "110px",
		height: "43px"
	},
	ersMenu: {
		fontFamily: studentTheme.fonts.primaryFont,
		listStyle: "none",
		listStyleType: "none",
		margin: 0,
		paddingInlineStart: 0
	},

	ersBranding: {
		display: "block",
		paddingTop: "0px",
		paddingBottom: "0px"
	},
	ersLeft: {
		float: "left"
	},
	ersRight: {
		float: "right"
	},
	ersMenuTarget: {
		display: "block",
		color: "#222",
		fontSize: "1rem",
		lineHeight: "1",
		boxSizing: "border-box",
		cursor: "pointer"
	},
	ersMenuItem: {
		float: "left",
		listStyleType: "none",
		margin: 0,
		padding: 0
	},
	ersMenuLink: {
		position: "relative",
		"& a": {
			padding: "1.1875rem 1rem",
			textDecoration: "none"
		}
	},
	ersUserMenu: {
		"& a": {
			padding: "0.6875rem 0.5rem ",
			textDecoration: "none"
		},
		"&:hover": {
			backgroundColor: studentTheme.colors.navMenuHover
		}
	},
	ersUserMenuHover: {
		backgroundColor: studentTheme.colors.navMenuHover
	},
	ersMenuIcon: {
		paddingRight: "0.25rem"
	},
	ersMenuText: {
		display: "flex"
	},
	ersUserAvatar: {
		display: "inline-block",
		textAlign: "center",
		backgroundColor: studentTheme.colors.mediumGray
	},
	ersMenuAvatar: {
		borderRadius: "1rem",
		minWidth: "2rem",
		height: "2rem",
		fontSize: "0.875rem",
		lineHeight: "2rem"
	},
	ersPopoverAvatar: {
		borderRadius: "1.25rem",
		minWidth: "2.5rem",
		height: "2.5rem",
		fontSize: "1rem",
		lineHeight: "2.5rem",
		marginRight: "1.25rem",
		fontFamily: studentTheme.fonts.ProximaNovaSemibold
	},
	ersUserInfo: {
		display: "block",
		fontSize: "1rem",
		lineHeight: "2rem",
		marginRight: "0.5rem",
		//overflow: 'hidden',
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		maxWidth: "240px",
		textAlign: "left",
		overflowX: "hidden",
		overflowY: "hidden"

		// @media screen and (min-width: 48.4375rem) {
		//     display: block;
		//     max-width: rem-calc(240);
		// }
	},
	ersUserMenuDetailed: {
		display: "flex",
		padding: "0.75rem 1rem"

		// @include breakpoint(large up) {
		//     min-width: rem-calc(260);
		//     max-width: rem-calc(320);
		// }
	},
	ersUserName: {
		fontFamily: studentTheme.fonts.primaryFontSemibold
	},
	ersUserLinks: {
		display: "flex",
		flexDirection: "column",
		fontFamily: studentTheme.fonts.primaryFont,
		fontSize: "0.8125rem"
	},
	ersDivider: {
		background: studentTheme.colors.mediumGray,
		height: "0.0625rem"
	},
	ersUserActions: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		padding: "0.625rem 0.5rem",
		backgroundColor: studentTheme.colors.whitesmokeGray
	},
	ersSecondaryButton: {
		backgroundColor: studentTheme.colors.secondaryColor,
		borderColor: studentTheme.colors.mediumGray,
		color: studentTheme.colors.black,
		fontSize: "0.75rem",
		lineHeight: "0.875rem",
		padding: "0.25rem 0.5rem",
		borderWidth: "0.0625rem",
		borderStyle: "solid",
		borderRadius: "0.25rem",
		cursor: "pointer",
		textDecoration: "none",
		"&:hover": {
			backgroundColor: studentTheme.colors.navButtonHover,
			textDecoration: "none"
		}
	},
	ersAccent: {
		height: "4px",
		background: `linear-gradient(0.25turn, ${studentTheme.colors.primaryColor}, ${studentTheme.colors.successColor}, ${studentTheme.colors.warningColor})`,
		width: "100%"
	}
};

export default TopNavStyles;
