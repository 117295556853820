import React from "react";
import Grid from "@mui/material/Grid";
import { faFileAlt } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAssignmentCardStyles from "./styles";

const AssignmentCard = ({
	title = "",
	dateString = "",
	referenceLink,
	art,
	controls
}) => {
	const classes = useAssignmentCardStyles();
	return (
		<Grid
			container
			direction="row"
			alignItems="stretch"
			className={classes.root}>
			<Grid item xs={12} sm={9} className={classes.cardBody}>
				{art}
				<Grid container direction="column" className={classes.content}>
					<Grid item className={classes.titleContainer}>
						<h4 className={classes.title}>{title}</h4>
					</Grid>
					<Grid item className={classes.date}>
						{dateString}
					</Grid>
					<Grid item className={classes.refLink}>
						{referenceLink && (
							<a href={referenceLink} target="_blank" rel="noopener noreferrer">
								<span className={classes.refIcon}>
									<FontAwesomeIcon icon={faFileAlt} />
								</span>
								Reference Sheet
							</a>
						)}
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} sm={3} className={classes.controls}>
				{controls}
			</Grid>
		</Grid>
	);
};

export default React.memo(AssignmentCard);
