import { exitDestinationConstants } from "_constants/primerPlayer";
import { useRouteMatch, useHistory } from "react-router-dom";

const useNavigation = () => {
	const { url } = useRouteMatch();
	const history = useHistory();
	const districtKey = url.split("/")[1];

	const districtRoute = (path, data = null) => {
		if (!districtKey) {
			return;
		}
		history.push(`/${districtKey}/${path}`, data);
	};

	const goLearn = () => {
		districtRoute("learn");
	};

	const goReview = () => {
		districtRoute("review");
	};

	const goError = () => {
		districtRoute("error");
	};

	const goLearnClassroom = classroomId => {
		districtRoute("learn", { classroomId });
	};

	const goReviewClassroom = classroomId => {
		districtRoute("review", { classroomId });
	};

	const assignmentUrl = primerId => `assignment/${primerId}`;

	const goPrimer = (
		primerId,
		exitDestination = exitDestinationConstants.LEARN
	) => {
		districtRoute(assignmentUrl(primerId), { exitDestination });
	};

	const goPrimerPostTest = (
		primerId,
		exitDestination = exitDestinationConstants.LEARN
	) => {
		districtRoute(`${assignmentUrl(primerId)}?key=post-test`, {
			exitDestination
		});
	};

	return {
		districtKey,
		goLearn,
		goReview,
		goError,
		goLearnClassroom,
		goReviewClassroom,
		goPrimer,
		goPrimerPostTest
	};
};

export default useNavigation;
