import React from "react";
import { Link } from "@mui/material";
import useFooterStyles from "./styles";

const Footer = () => {
	const classes = useFooterStyles();
	const year = new Date().getFullYear();
	return (
		<footer className={classes.root}>
			<div className={classes.row}>
				<span className={classes.copyright}>
					&copy;{`${year} Ellevation, Inc.`}
				</span>
				<Link
					className={classes.link}
					href={"https://ellevationeducation.com/home/default"}
					target="_blank"
					rel="noopener noreferrer"
				>
					Home
				</Link>
				<Link
					className={classes.link}
					href={"http://ellevationeducation.com/our-story"}
					target="_blank"
					rel="noopener noreferrer"
				>
					About
				</Link>
				<Link
					className={classes.link}
					href={"https://ellevationeducation.com/platform-privacy-policy"}
					target="_blank"
					rel="noopener noreferrer"
				>
					Privacy Policy
				</Link>
				<Link
					className={classes.link}
					href={"https://ellevationeducation.com/platform-legal-notices"}
					target="_blank"
					rel="noopener noreferrer"
				>
					Terms of Service
				</Link>
			</div>
		</footer>
	);
};

export default Footer;
