import makeStyles from "@mui/styles/makeStyles";
import studentTheme from "themes/StudentTheme";

const useLearnAssignmentPostTestControlsStyles = makeStyles({
	controls: {
		fontFamily: studentTheme.fonts.primaryFont,
		width: "100%",
		height: "100%",
		justifyContent: "center"
	},
	button: {
		height: "3.25rem",
		width: "100%",
		justifyContent: "center",
		fontSize: "1.25rem",
		backgroundColor: studentTheme.colors.primaryColor
	},
	reviewButton: {
		backgroundColor: studentTheme.colors.lightGray,
		color: studentTheme.colors.black,
		border: "solid 1px",
		borderColor: studentTheme.colors.mediumGray
	},
	spacer: {
		padding: "0.625rem"
	}
});

export default useLearnAssignmentPostTestControlsStyles;
