import React from "react";
import Grid from "@mui/material/Grid";
import AssignmentSummary from "../AssignmentSummary";
import useNavigation from "_hooks/useNavigation";
import useReviewAssignmentControlsStyles from "./styles";
import { exitDestinationConstants } from "_constants/primerPlayer";

const ReviewAssignmentControls = ({ assignment }) => {
	const classes = useReviewAssignmentControlsStyles();
	const { goPrimer, goPrimerPostTest } = useNavigation();

	const primerComplete = !!assignment.students[0].completedOn;
	const postTestComplete = !!assignment.students[0].posttestCompletedOn;
	return (
		<Grid item container direction="column" className={classes.controls}>
			<div className={classes.summaries}>
				<div className={classes.summary}>
					<AssignmentSummary
						isPrimer={true}
						isComplete={primerComplete}
						numerator={
							primerComplete ? assignment.students[0].vocabCorrect : ""
						}
						denominator={
							primerComplete ? assignment.students[0].vocabTotal : ""
						}
						toPlayer={() =>
							goPrimer(assignment.id, exitDestinationConstants.REVIEW)
						}
					/>
				</div>
				<div className={classes.summary}>
					<AssignmentSummary
						isPrimer={false}
						isComplete={postTestComplete}
						numerator={
							postTestComplete ? assignment.students[0].posttestCorrect : ""
						}
						denominator={
							postTestComplete ? assignment.students[0].posttestTotal : ""
						}
						isDisabled={!primerComplete}
						toPlayer={() =>
							goPrimerPostTest(assignment.id, exitDestinationConstants.REVIEW)
						}
					/>
				</div>
			</div>
		</Grid>
	);
};

export default ReviewAssignmentControls;
