import makeStyles from "@mui/styles/makeStyles";

const useLoginStyles = makeStyles(theme => ({
	container: {
		width: "50%",
		paddingRight: "0.9375rem",
		paddingLeft: "0.9375rem"
	},
	bucketContent: {
		display: "flex",
		flexDirection: "column",
		padding: "0.5rem 1.25rem 1.5rem 1.5rem",
		margin: 0
	},
	introText: {
		paddingBottom: "0.1875rem"
	},
	textFieldContainer: {
		display: "flex",
		flexDirection: "column"
	},
	textField: {
		marginBottom: "1rem"
	},
	buttonContainer: {
		marginLeft: "33.3333%",
		paddingLeft: "1.875rem"
	},
	updateButton: {
		fontFamily: theme.typography.fontFamily,
		fontSize: "0.875rem",
		fontWeight: 400,
		padding: "0.1875rem 0.875rem 0.1875rem 0.875rem",
		marginBottom: "1rem",
		borderRadius: "0.25rem",
		backgroundColor: theme.palette.primary.main,
		border: "1px solid transparent",
		color: theme.palette.primary.contrastText,
		"&:hover": {
			cursor: "pointer",
			backgroundColor: theme.palette.primary.dark
		},
		"&:focus": {
			outline: 0
		}
	}
}));

export default useLoginStyles;
