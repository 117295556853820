// learn page
export const learnPrimerMessage =
	"Congratulations! You've completed all your assigned Primers for now.";
export const learnMathTestMessage =
	"Good Work! You've completed all your assigned Math Tests for now.";
export const learnCheckResultsMessage = "Check out your Results";
export const learnNoDueDateMessage = "No due date";
export const learnStartPrimerMessage = "Start Primer";
export const learnContinuePrimerMessage = "Continue";
export const learnReviewPrimerMessage = "Review Primer";
export const learnStartMathTestMessage = "Start Math Test";
