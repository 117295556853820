import React from "react";
import useLandingPageStyles from "./styles";
import backpack from "img/backpack.png";
import Footer from "components/Footer";

const LandingPage = () => {
	const classes = useLandingPageStyles();
	return (
		<div className={classes.root}>
			<div className={classes.topContent}>
				<div className={classes.box}>
					<h3 className={classes.header}>
						Students, welcome to Ellevation Math!
					</h3>
					<div className={classes.body}>
						<img src={backpack} className={classes.image} />
						<div className={classes.content}>
							<p>
								{
									"To log in, please ask your teacher for your school's unique link."
								}
							</p>
							<div className={classes.urlDescription}>
								<p className={classes.pOverride}>
									{"It should look like this:"}
								</p>
								<p className={classes.pOverride}>
									<em>{"student.ellevationeducation.com/districtname"}</em>
								</p>
							</div>
						</div>
					</div>
				</div>
				<a
					className={classes.link}
					href={"https://app.ellevationeducation.com"}
				>
					{"Looking for the educator login to Ellevation?"}
				</a>
			</div>
			<Footer />
		</div>
	);
};

export default LandingPage;
