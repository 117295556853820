import makeStyles from "@mui/styles/makeStyles";
import studentTheme from "themes/StudentTheme";

const useClassroomAssignmentContainerStyles = makeStyles(theme => ({
	classroomTitleContainer: {
		cursor: "pointer"
	},
	classroomTitle: {
		fontFamily: studentTheme.fonts.primaryFontSemibold,
		fontSize: "1.875rem",
		fontWeight: "normal"
	},
	expandIcon: {
		paddingRight: "1.25rem"
	},
	"@media only screen and (max-width : 600px)": {
		classroomTitle: {
			marginLeft: "0",
			marginRight: "0"
		}
	},
	statusContainer: {
		marginTop: theme.spacing(5),
		marginBottom: theme.spacing(5)
	}
}));

export default useClassroomAssignmentContainerStyles;
