import React from "react";
import useButtonStyles from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Button = ({
	buttonText = "",
	buttonClass = "",
	onClick,
	icon = "",
	iconPosition = ""
}) => {
	const classes = useButtonStyles();
	const iconElement = icon && <FontAwesomeIcon icon={icon} size={"xs"} />;
	if (icon && !iconPosition) {
		iconPosition = "after";
	}
	const buttonPadding =
		iconPosition === "before"
			? classes.iconLeft
			: iconPosition === "after"
			? classes.iconRight
			: "";
	return (
		<button onClick={onClick} className={`${buttonClass}  ${classes.root}`}>
			<div className={classes.innerButton}>
				{iconPosition === "before" && iconElement}
				<span className={buttonPadding}>{buttonText}</span>
				{iconPosition === "after" && iconElement}
			</div>
		</button>
	);
};

export default Button;
