import makeStyles from "@mui/styles/makeStyles";

const useCardArtStyles = makeStyles({
	artContainer: {
		minWidth: 180,
		minHeight: 180,
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
		backgroundSize: "contain"
	}
});

export default useCardArtStyles;
