import makeStyles from "@mui/styles/makeStyles";
import studentTheme from "themes/StudentTheme";

const useReviewAssignmentControlsStyles = makeStyles({
	controls: {
		fontFamily: studentTheme.fonts.primaryFont,
		width: "100%",
		height: "100%",
		justifyContent: "center"
	},
	summaries: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between"
	},
	"@media only screen and (max-width : 1168px)": {
		summaries: {
			flexDirection: "column"
		},
		summary: {
			"&:first-child": {
				marginBottom: "0.625rem"
			}
		}
	}
});

export default useReviewAssignmentControlsStyles;
