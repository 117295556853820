import { createTheme } from "@mui/material/styles";

const studentTheme = createTheme({
	colors: {
		primaryColor: "#1f51a1",
		secondaryColor: "#e7e7e7",
		successColor: "#8ac91e",
		warningColor: "#f08a24",
		alertColor: "#f04124",

		// Grayscale Palette
		white: "#ffffff",
		mildGray: "#fafafa",
		whitesmokeGray: "#f5f5f5",
		whitesmokierGray: "#eaeaea",
		lightGray: "#e7e7e7",
		darkGray: "#dfdfdf",
		mediumGray: "#cccccc",
		darkerGray: "#8a8a8a",
		silverGray: "#888888",
		footerGray: "#5a5e63",
		charcoalGray: "#474747",
		imageGray: "#ebebeb",
		black: "#222222",
		gray5: "#cccccc",
		gray7: "#aaaaaa",
		gray8: "#ceccce",
		gray9: "#666666",

		// need revision
		navButtonHover: "#c4c4c4",
		navMenuHover: "#e4e4e4",

		// Red
		lightRed: "#fbd0c8",
		mediumRed: "#f8a092",
		darkRed: "#c2270d",

		// Orange
		orange1: "#f7941e",

		// Yellow
		lightYellow: "#fbe2c9",
		mediumYellow: "#f8c692",
		darkYellow: "#c2670d",
		backgroundYellow: "#f8f6e8",

		// Blue
		darkBlue: "#1b468a",
		blue1: "#1f51a1",
		blue2: "#ECF0F9"
	},
	fonts: {
		primaryFont: "ProximaNova-Regular",
		primaryFontSemibold: "ProximaNova-Semibold"
	}
});

export default studentTheme;
