import makeStyles from "@mui/styles/makeStyles";
import studentTheme from "themes/StudentTheme";

const useTextFieldStyles = makeStyles({
	inputContainer: {
		display: "flex"
	},
	label: {
		display: "flex",
		flexBasis: "33.333%",
		alignItems: "center",
		marginRight: "1.875rem",
		fontFamily: `${studentTheme.fonts.primaryFontSemibold}`,
		fontSize: "0.875rem",
		fontWeight: 400
	},
	rightAlignLabel: {
		justifyContent: "flex-end"
	},
	textField: {
		fontFamily: `${studentTheme.fonts.primaryFont}`,
		fontSize: "0.875rem",
		width: props => `${props.inputWidth}%`,
		lineHeight: "normal",
		margin: 0,
		padding: "0.5rem",
		borderRadius: "0.25rem",
		boxShadow: "inset 0 1px 2px rgba(34,34,34,.1)",
		"&:focus": {
			backgroundColor: `${studentTheme.colors.white}`,
			border: `1px solid ${studentTheme.colors.darkerGray}`,
			outline: 0
		}
	},
	basicTextField: {
		backgroundColor: `${studentTheme.colors.white}`,
		border: `1px solid ${studentTheme.colors.mediumGray}`,
		"&:focus": {
			border: `1px solid ${studentTheme.colors.darkerGray}`,
			boxShadow: `0 0 5px ${studentTheme.colors.mediumGray}`
		}
	},
	errorTextField: {
		backgroundColor: `${studentTheme.colors.lightRed}`,
		border: `1px solid ${studentTheme.colors.alertColor}`
	},
	textFieldMessage: {
		fontFamily: `${studentTheme.fonts.primaryFont}`,
		fontSize: "0.75rem",
		margin: "0.25rem 0rem 0.25rem 33.3333%",
		color: `${studentTheme.colors.alertColor}`,
		paddingLeft: "1.875rem"
	}
});

export default useTextFieldStyles;
