import moment from "moment";

export const dateSort = (key, options = { isAscending: true }) => (a, b) => {
	const order = options.isAscending ? 1 : -1;
	if (a[key] === b[key]) {
		return 0;
	}
	if (a[key] && !b[key]) {
		return -1 * order;
	}
	if (b[key] && !a[key]) {
		return 1 * order;
	}
	return (moment(a[key]) - moment(b[key])) * order;
};

export const alphanumericSort = (key, options = { isAscending: true }) => (
	a,
	b
) => {
	const order = options.isAscending ? 1 : -1;
	return (
		(a[key] || "").localeCompare(b[key] || "", undefined, {
			sensitivity: "base"
		}) * order
	);
};
