import React from "react";
import useAlertStyles from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Alert = ({ type, message, icon }) => {
	const classes = useAlertStyles({ type: type });
	return (
		<div className={classes.root}>
			<div className={classes.icon}>
				{icon && <FontAwesomeIcon icon={icon} />}
			</div>
			<div>{message}</div>
		</div>
	);
};

export default Alert;
