import makeStyles from "@mui/styles/makeStyles";
import studentTheme from "themes/StudentTheme";

const useLoginStyles = makeStyles({
	container: {
		width: "50%",
		paddingRight: "0.9375rem",
		paddingLeft: "0.9375rem"
	},
	paper: {
		border: `1px solid ${studentTheme.colors.mediumGray}`
	},
	bucketContent: {
		display: "flex",
		flexDirection: "column",
		padding: "0.5rem 1.25rem 1.5rem 1.5rem"
	},
	textFieldContainer: {
		display: "flex",
		flexDirection: "column"
	},
	textField: {
		marginBottom: "1rem"
	},
	buttonContainer: {
		display: "flex",
		flexDirection: "column",
		marginLeft: "33.3333%",
		paddingLeft: "1.875rem"
	},
	loginButton: {
		fontFamily: `${studentTheme.fonts.primaryFont}`,
		fontSize: "0.875rem",
		fontWeight: 400,
		padding: "0.1875rem 0.875rem 0.1875rem 0.875rem",
		width: "4.375rem",
		marginBottom: "1rem",
		marginRight: "0.3125rem",
		borderRadius: "0.25rem",
		backgroundColor: `${studentTheme.colors.primaryColor}`,
		border: "1px solid transparent",
		color: `${studentTheme.colors.white}`,
		"&:hover": {
			cursor: "pointer",
			backgroundColor: `${studentTheme.colors.darkBlue}`
		},
		"&:focus": {
			outline: 0
		}
	},
	loginHelp: {
		fontFamily: `${studentTheme.fonts.primaryFont}`,
		fontSize: "0.9375rem",
		color: `${studentTheme.colors.darkBlue}`,
		"&:hover": {
			cursor: "pointer"
		}
	}
});

export default useLoginStyles;
