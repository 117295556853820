import makeStyles from "@mui/styles/makeStyles";
import studentTheme from "themes/StudentTheme";

export const useFooterStyles = makeStyles({
	root: {
		marginTop: "4rem",
		padding: "1.5rem 0",
		background: studentTheme.colors.footerGray
	},
	row: {
		maxWidth: "75rem",
		display: "flex",
		flexFlow: "row wrap"
	},
	link: {
		color: studentTheme.colors.white,
		fontSize: "0.875rem",
		lineHeight: 1,
		marginRight: "1rem",
		cursor: "pointer",
		"&:hover": {
			textDecoration: "none",
			color: `${studentTheme.colors.white} !important`
		}
	},
	copyright: {
		color: studentTheme.colors.white,
		fontSize: "0.875rem",
		marginRight: "1rem",
		marginLeft: "1.875rem"
	}
});

export default useFooterStyles;
