import { fetchAuthSession } from "aws-amplify/auth";
import { errorRedirect } from "./helpers";
import * as Sentry from "@sentry/browser";
import Cookies from "js-cookie";

export const request = async ({
	url,
	init = {},
	isStudent = true,
	needsAuth = true,
	redirectOnError = true
}) => {
	if (needsAuth && isStudent) {
		const currentSession = await fetchAuthSession();
		const authHeader = currentSession?.tokens?.idToken?.toString();
		if (!init.headers) {
			init["headers"] = {};
		}
		init.headers["Authorization"] = authHeader;
	} else if (needsAuth && !isStudent) {
		const csrf = Cookies.get("csrf");
		if (csrf) {
			if (!init.headers) {
				init["headers"] = {};
			}
			init.headers["x-csrf-token"] = csrf;
			init.xhrFields = {
				withCredentials: true
			};
			init.credentials = "include";
		}
	}

	try {
		let response = await fetch(
			// eslint-disable-next-line no-undef
			`${process.env.REACT_APP_API_ENDPOINT}${url}`,
			init
		);
		if (response.status !== 200) {
			throw Error(response.statusText);
		}
		const text = await response.text();
		return text.length ? JSON.parse(text) : {};
	} catch (err) {
		Sentry.captureException(err);
		if (redirectOnError) {
			errorRedirect();
		}
	}
};

//student auth
export const getStudentClassrooms = async () => {
	let url = "/classroom?limit=2000";
	return await request({ url });
};

export const getStudentAssignment = async assignmentId => {
	let url = `-assignment/classroom_assignment/${assignmentId}`;
	return await request({ url });
};

export const getClassroomAssignments = async classroomId => {
	const url = `-assignment/classroom/${classroomId}/assignment`;
	return await request({ url });
};

export const postUserActivity = async body => {
	let url = "-sp1/user_activity";
	const init = {
		method: "POST",
		headers: {
			"Content-Type": "application/json"
		},
		body: JSON.stringify(body)
	};
	return await request({ url, init, redirectOnError: false });
};

export const postAssignmentEvent = async (studentAssignmentId, payload) => {
	const url = `-assignment/student_assignment/${studentAssignmentId}/event`;
	const init = {
		method: "POST",
		headers: {
			"Content-Type": "application/json"
		},
		body: JSON.stringify(payload)
	};
	return await request({ url, init, redirectOnError: false });
};

export const submitAssignmentResponses = async ({
	studentAssignmentId,
	assessmentId,
	responses
}) => {
	const url = `-assignment/student_assignment/${studentAssignmentId}/response`;
	const init = {
		method: "POST",
		headers: {
			"Content-Type": "application/json"
		},
		body: JSON.stringify({
			assessmentId,
			responses
		})
	};
	return await request({ url, init, redirectOnError: false });
};

//staff auth
export const getPrimer = async primerId => {
	let url = `-content/primer/${primerId}`;
	return await request({ url, isStudent: false });
};

//no auth
export const getLoginInfo = async assertionToken => {
	let url = "-auth/login_info";
	const init = {
		headers: {
			Authorization: assertionToken
		}
	};
	return await request({ url, init, needsAuth: false });
};

export const getOrganization = async districtKey => {
	let url = `-sp1/organization?key=${districtKey}`;
	return await request({ url, needsAuth: false });
};

export const getTextToSpeech = async text => {
	let url = "-komodo/text_speech";
	const init = {
		method: "POST",
		headers: {
			"Content-Type": "application/json"
		},
		body: JSON.stringify({ text })
	};
	return await request({ url, init, redirectOnError: false });
};
