import makeStyles from "@mui/styles/makeStyles";
import studentTheme from "themes/StudentTheme";

const useButtonStyles = makeStyles({
	root: {
		color: studentTheme.colors.white,
		borderRadius: "0.25rem",
		border: "none",
		fontFamily: studentTheme.fonts.primaryFont,
		display: "flex",
		alignItems: "center",
		cursor: "pointer"
	},

	iconRight: {
		paddingRight: "0.625rem"
	},
	iconLeft: {
		paddingLeft: "0.625rem"
	}
});

export default useButtonStyles;
