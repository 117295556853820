import React from "react";
import useCardArtStyles from "./styles";

const CardArt = ({ imageSrc }) => {
	const classes = useCardArtStyles();
	return (
		<div
			className={classes.artContainer}
			style={{ backgroundImage: `url(${imageSrc})` }}
			data-testid="card-art"
		/>
	);
};
export default CardArt;
