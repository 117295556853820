import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "components/Button";
import useNavigation from "_hooks/useNavigation";
import useLearnAssignmentPostTestControlsStyles from "./styles";
import {
	learnReviewPrimerMessage,
	learnStartMathTestMessage
} from "copy/classroomsPage";

const LearnAssignmentPostTestControls = ({ assignment }) => {
	const classes = useLearnAssignmentPostTestControlsStyles();
	const { goPrimer, goPrimerPostTest } = useNavigation();
	const toPlayer = () => goPrimer(assignment.id);
	const toPostTest = () => goPrimerPostTest(assignment.id);

	return (
		<Grid item container direction="column" className={classes.controls}>
			<Button
				buttonText={learnReviewPrimerMessage}
				onClick={toPlayer}
				buttonClass={`${classes.reviewButton} ${classes.button}`}
			/>
			<Box className={classes.spacer} />
			<Button
				buttonText={learnStartMathTestMessage}
				onClick={toPostTest}
				buttonClass={classes.button}
			/>
		</Grid>
	);
};

export default LearnAssignmentPostTestControls;
