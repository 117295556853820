import makeStyles from "@mui/styles/makeStyles";
import studentTheme from "themes/StudentTheme";

const usePopoverContentStyles = makeStyles({
	ersMenu: {
		fontFamily: studentTheme.fonts.primaryFont,
		listStyle: "none",
		listStyleType: "none",
		margin: 0,
		paddingInlineStart: 0
	},
	userInfo: {
		display: "flex"
	},
	ersUserMenuDetailed: {
		display: "flex",
		flexDirection: "column",
		padding: "0.75rem 1rem"

		// @include breakpoint(large up) {
		//     min-width: rem-calc(260);
		//     max-width: rem-calc(320);
		// }
	},
	ersUserAvatar: {
		display: "inline-block",
		textAlign: "center",
		backgroundColor: studentTheme.colors.mediumGray
	},
	ersPopoverAvatar: {
		borderRadius: "1.25rem",
		minWidth: "2.5rem",
		height: "2.5rem",
		fontSize: "1rem",
		lineHeight: "2.5rem",
		marginRight: "1.25rem",
		fontFamily: studentTheme.fonts.ProximaNovaSemibold
	},
	ersUserName: {
		fontFamily: studentTheme.fonts.primaryFontSemibold
	},
	ersUserLinks: {
		display: "flex",
		flexDirection: "column",
		fontFamily: studentTheme.fonts.primaryFont,
		fontSize: "0.8125rem"
	},
	ersDivider: {
		background: studentTheme.colors.mediumGray,
		height: "0.0625rem"
	},
	ersUserActions: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		padding: "0.625rem 0.5rem",
		backgroundColor: studentTheme.colors.whitesmokeGray,
		borderTop: `0.03125rem solid ${studentTheme.colors.lightGray}`
	},
	privacyLink: {
		fontSize: "12px",
		color: studentTheme.colors.primaryColor,
		margin: "0rem 0.5rem"
	},
	ersSecondaryButton: {
		flex: 1,
		display: "flex",
		justifyContent: "center",
		backgroundColor: studentTheme.colors.secondaryColor,
		borderColor: studentTheme.colors.mediumGray,
		color: studentTheme.colors.black,
		fontSize: "0.75rem",
		lineHeight: "0.875rem",
		padding: "0.25rem 0.5rem",
		borderWidth: "0.0625rem",
		borderStyle: "solid",
		borderRadius: "0.25rem",
		cursor: "pointer",
		textDecoration: "none",
		"&:hover": {
			backgroundColor: studentTheme.colors.navButtonHover,
			textDecoration: "none"
		}
	},
	signOut: {
		display: "flex",
		justifyContent: "center",
		padding: "0.5rem"
	}
});

export default usePopoverContentStyles;
