import makeStyles from "@mui/styles/makeStyles";
import studentTheme from "themes/StudentTheme";
const useErrorPageStyles = makeStyles({
	root: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center"
	},
	content: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		paddingTop: "6.25rem"
	},
	image: {
		height: "18.75rem",
		width: "18.75rem",
		borderRadius: "50%"
	},
	message: {
		fontFamily: studentTheme.fonts.primaryFontSemibold,
		margin: "1.25rem"
	},
	button: {
		backgroundColor: studentTheme.colors.primaryColor,
		paddingLeft: "1.25rem",
		paddingRight: "1.25rem"
	}
});
export default useErrorPageStyles;
