import makeStyles from "@mui/styles/makeStyles";

const useClassroomsPageStyles = makeStyles({
	classrooms: {
		margin: "1.875rem auto",
		padding: "0 1.875rem",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		maxWidth: "75rem"
	}
});

export default useClassroomsPageStyles;
