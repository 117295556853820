import React, { useEffect, useState } from "react";
import { useLocation, useRouteMatch } from "react-router";
import { Grid } from "@mui/material/";
import SRPLogin from "./components/SRPLogin";
import SSOLogin from "./components/SSOLogin";
import useLoginPageStyles from "./styles";
import Footer from "components/Footer";
import { getOrganization } from "lib/api/primerApi";
import { sessionStorageConstants } from "_constants";

const LoginPage = ({ districtId, onSRPLogin }) => {
	const classes = useLoginPageStyles();
	const [isLoading, setIsLoading] = useState(true);
	const [isSSO, setIsSSO] = useState("");
	const [ssoInfo, setSsoInfo] = useState({
		title: "",
		loginUrl: "",
		imageUrl: ""
	});
	const { url } = useRouteMatch();
	const location = useLocation();
	const districtKey = url.split("/")[1];

	useEffect(() => {
		const getSSOness = async () => {
			const response = await getOrganization(districtKey);
			if (!response) {
				return;
			}
			const {
				result: { ssoProviders }
			} = response;
			const tempIsSSO = ssoProviders.length > 0;
			setIsSSO(tempIsSSO);
			if (tempIsSSO) {
				/* istanbul ignore else */
				if (location && location.state && location.state.from) {
					window.sessionStorage.setItem(
						sessionStorageConstants.SSO_DESTINATION_KEY,
						location.state.from.pathname
					);
				}

				setSsoInfo({
					...ssoInfo,
					...ssoProviders[0]
				});
			}
			setIsLoading(false);
		};
		getSSOness();
	}, []);

	return (
		<div className={classes.root}>
			<div className={classes.container}>
				{!isLoading && (
					<Grid container justifyContent="center" alignItems="center">
						<Grid item lg={5} md={6} sm={9} xs={12}>
							<div className={classes.box}>
								<div className={classes.bucketHeader}>
									<div className={classes.header}>
										{"Student log in to Ellevation Math"}
									</div>
								</div>

								{isSSO ? (
									<SSOLogin
										providerName={ssoInfo.title}
										providerUrl={ssoInfo.loginUrl}
										logoUrl={ssoInfo.imageUrl}
									/>
								) : (
									<SRPLogin districtId={districtId} onSRPLogin={onSRPLogin} />
								)}
							</div>
						</Grid>
					</Grid>
				)}
			</div>
			<Footer />
		</div>
	);
};

export default LoginPage;
