import { useReducer } from "react";
import {
	getClassroomAssignments,
	getStudentClassrooms
} from "lib/api/primerApi";
import { initialState, reducer, actionTypes } from "reducers/ClassroomReducer";

const loadAssignmentData = async (classroomId, dispatch) => {
	const response = await getClassroomAssignments(classroomId);
	if (!response) {
		return;
	}

	dispatch({
		type: actionTypes.SET_ASSIGNMENTS,
		classroomId: classroomId,
		assignments: response.assignments
	});
};

const onClassroomToggle = async (classroomId, state, dispatch) => {
	if (!state.expanded[classroomId] && state.data && !state.data[classroomId]) {
		await loadAssignmentData(classroomId, dispatch);
	}
	dispatch({ type: actionTypes.TOGGLE_CLASSROOM, classroomId: classroomId });
};

const setClassroomData = async (dispatch, selectedClassroomId) => {
	const classrooms = await getStudentClassrooms();
	if (!classrooms) {
		return;
	}
	let expandedClassroomId = selectedClassroomId;
	if (classrooms.results.length === 1) {
		// expand by default if only one classroom
		expandedClassroomId = classrooms.results[0].id;
	}
	if (expandedClassroomId) {
		await loadAssignmentData(expandedClassroomId, dispatch);
	}
	dispatch({
		type: actionTypes.SET_CLASSROOMS,
		data: classrooms.results,
		expandedClassroomId
	});
};
const useClassrooms = () => {
	const [state, dispatch] = useReducer(reducer, initialState);

	const setClassrooms = async (selectedClassroomId = null) => {
		await setClassroomData(dispatch, selectedClassroomId);
	};

	const onToggle = classroomId => {
		onClassroomToggle(classroomId, state, dispatch);
	};

	return { state, setClassrooms, onToggle };
};

export default useClassrooms;
