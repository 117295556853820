const transformations = [
	[/[^\x20-\x7E]/gim, ""], // remove non-characters
	[/_{3,}/g, "BLANK"], // replace 3 or more _ with blank
	[/(<([^>\s]+)>)/gi, " "], // replace all opening and closing html tags with a space
	[/(&le;)/g, "Is LESS THAN OR EQUAL TO"],
	[/(&ge;)/g, "Is GREATER THAN OR EQUAL TO"],
	[/(<)/g, "Is LESS THAN"],
	[/(>)/g, "Is GREATER THAN"],
	[/(&lt;)/g, "Is LESS THAN"],
	[/(&gt;)/g, "Is GREATER THAN"],
	[/(&([^;\s]+);)/gi, " "], // remove remaining escaped values
	[/ {2,}/g, " "] // remove multiple spaces
];

/* istanbul ignore next */
export const ttsTransform = text => {
	try {
		// filter out non-string or number values
		if (!["string", "number"].includes(typeof text)) {
			return null;
		}

		const sanitizedText = text.toString().trim();
		// disable tts for mathjax
		if (sanitizedText.includes("\\[")) {
			return null;
		}

		const transformed = transformations.reduce((prev, [from, to]) => {
			return prev.replace(from, to);
		}, sanitizedText);

		return transformed.trim();
	} catch (error) {
		return null;
	}
};
