import React from "react";
import ReviewAssignmentControls from "../ReviewAssignmentControls";
import AssignmentCard from "../AssignmentCard";
import CardArt from "components/CardArt";
import moment from "moment";

const ReviewAssignmentsSummary = ({ assignments }) => {
	return (
		<>
			{assignments.map(assignment => (
				<AssignmentCard
					key={assignment.id}
					title={assignment.title}
					dateString={`Assigned on ${moment(assignment.assignOn).format(
						"MMM D"
					)}`}
					art={<CardArt imageSrc={assignment.content.coverImageUrl} />}
					referenceLink={assignment.content.refSheetUrl}
					controls={<ReviewAssignmentControls assignment={assignment} />}
				/>
			))}
		</>
	);
};

export default ReviewAssignmentsSummary;
