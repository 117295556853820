import { useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Box, Typography, Avatar } from "@ellevation/backpack";
import { AssessmentContainer } from "./components";
import { StudentLogin } from "./components";
import { pocAssessmentFetchHelper } from "lib/api/pocAssessmentAPI";
import { usePoc } from "./PocContextProvider";
import logo from "img/nav_logo.png";

const AssessmentsPage = ({}) => {
	const { assignmentId } = useParams();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const [loginCode, setLoginCode] = useState(null);
	const [content, setContent] = useState(null);
	const [assessmentId, setAssessmentId] = useState(null);
	const [error, setError] = useState(null);

	const { currentContentIndex, responses } = usePoc();

	const domains = ["Reading", "Writing", "Listening", "Speaking"];
	const validDomainParam = domains.find(domain =>
		searchParams.has(domain.toLowerCase())
	);
	const domain = content?.content.domain || validDomainParam;

	const getAssessment = async code => {
		const response = await pocAssessmentFetchHelper(
			`assignment/${assignmentId}`,
			"GET",
			null,
			code
		);
		if (!response.ok) {
			setError("Incorrect code, ask your teacher for help.");
		} else {
			const result = await response.json();
			setContent(result);
			setAssessmentId(result.content.assessmentId);
			setLoginCode(code);
			sessionStorage.setItem("loginCode", code);
		}
	};
	return (
		<>
			<Box
				sx={{
					position: "sticky",
					top: 0,
					zIndex: 1,
					backgroundColor: "white"
				}}>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						p: "8px 16px",
						alignItems: "center"
					}}>
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<img src={logo} alt="Ellevation Logo" />
						{domain && (
							<Typography
								sx={{
									fontSize: "21px",
									fontWeight: 400,
									pl: "40px"
								}}>
								{domain}
							</Typography>
						)}
					</Box>
					{content && (
						<Box
							sx={{
								display: "flex",
								flexDirection: "row"
							}}>
							{!responses[currentContentIndex]?.intro &&
								currentContentIndex < responses.length && (
									<Typography
										sx={{ mr: 2, mt: 1, fontSize: "21px", fontWeight: "400" }}>
										{responses[currentContentIndex]?.question?.questionNumber}{" "}
										of {content.content.items.length}
									</Typography>
								)}
							<Avatar>
								{content.user.firstname[0] + content.user.lastname[0]}
							</Avatar>
						</Box>
					)}
				</Box>
				<Box
					sx={{
						height: "4px",
						width: "100%",
						background: "linear-gradient(90deg, #1f51a1, #8ac91e, #f08a24)"
					}}
				/>
			</Box>
			<Box>
				{loginCode ? (
					<AssessmentContainer data={content} assessmentId={assessmentId} />
				) : (
					<StudentLogin
						sendCode={getAssessment}
						error={error}
						domain={domain}
					/>
				)}
			</Box>
		</>
	);
};

export default AssessmentsPage;
