import makeStyles from "@mui/styles/makeStyles";
import studentTheme from "themes/StudentTheme";

const useLearnAssignmentsSummaryStyles = makeStyles({
	title: {
		fontFamily: studentTheme.fonts.primaryFont,
		fontSize: "1.25rem",
		color: studentTheme.colors.darkerGray,
		fontWeight: "normal"
	},
	noResults: {
		fontFamily: studentTheme.fonts.primaryFont,
		padding: "0.625rem 0rem 0.625rem 0rem",
		color: studentTheme.colors.darkerGray
	},
	bothEmpty: {
		backgroundColor: studentTheme.colors.whitesmokierGray,
		border: `1px solid ${studentTheme.colors.mediumGray}`,
		borderRadius: `0.25rem`,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		padding: "1.25rem",
		boxSizing: "border-box",
		marginTop: "1.25rem"
	},
	bothEmptyText: {
		display: "block",
		textAlign: "center"
	},
	bothEmptyLink: {
		color: studentTheme.colors.primaryColor,
		"&:hover": {
			cursor: "pointer",
			textDecoration: "underline"
		}
	}
});

export default useLearnAssignmentsSummaryStyles;
