import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import App from "./App";
import "./index.css";

Sentry.init({
	dsn:
		"https://6f57a3e04eaf456eb2f6f9cc9e94e7d8@o363772.ingest.sentry.io/5371448",
	environment:
		// eslint-disable-next-line no-undef
		process.env.REACT_APP_ENV
});

ReactDOM.render(<App />, document.getElementById("root"));
