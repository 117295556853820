import makeStyles from "@mui/styles/makeStyles";
import studentTheme from "themes/StudentTheme";

let types = {
	error: {
		backgroundColor: studentTheme.colors.lightRed,
		border: `1px solid ${studentTheme.colors.mediumRed}`,
		color: studentTheme.colors.darkRed
	},
	warning: {
		backgroundColor: studentTheme.colors.lightYellow,
		border: `1px solid ${studentTheme.colors.mediumYellow}`,
		color: studentTheme.colors.darkYellow
	},
	info: {
		backgroundColor: studentTheme.colors.lightGray,
		border: `1px solid ${studentTheme.colors.mediumGray}`,
		color: studentTheme.colors.darkerGray
	}
};
const useAlertStyles = makeStyles({
	root: {
		fontFamily: studentTheme.fonts.primaryFont,
		fontSize: "0.875rem",
		padding: "1rem 0.9375rem 1rem 0.9375rem",
		borderRadius: "0.25rem",
		marginBottom: "1rem",
		display: "flex",
		flexDirection: "row",
		backgroundColor: props => types[props.type].backgroundColor,
		border: props => types[props.type].border,
		color: props => types[props.type].color
	},
	icon: {
		verticalAlign: "top",
		paddingRight: "0.3125rem"
	}
});

export default useAlertStyles;
