import React from "react";
import character from "img/Hello.png";
import useStyles from "./styles";

const Banner = ({ isLearnPage }) => {
	const styles = useStyles();

	return (
		<div className={styles.banner}>
			<div className={styles.bannerImage} />
			<div className={styles.bannerContent}>
				<div className={styles.welcomeContainer}>
					<img
						src={character}
						alt="Character Image"
						className={styles.character}
					/>
					<span className={styles.welcomeText}>Welcome!</span>
				</div>
				{isLearnPage ? (
					<p className={styles.description}>
						<span>Let&apos;s </span>
						<strong>learn</strong>
						<span> something new today.</span>
					</p>
				) : (
					<p className={styles.description}>
						<span>Let&apos;s </span>
						<strong>review</strong>
						<span> what you&apos;ve learned.</span>
					</p>
				)}
			</div>
		</div>
	);
};

export default Banner;
