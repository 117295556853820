import React, { useState } from "react";
import Alert from "components/Alert";
import TextField from "components/TextField";
import {
	faTimesCircle,
	faExclamationTriangle
} from "@fortawesome/pro-solid-svg-icons";
import useLoginStyles from "./styles";

const Login = ({
	login,
	setLogin,
	password,
	setPassword,
	onLogin,
	errorMessage
}) => {
	const [displayHelpMessage, setDisplayHelpMessage] = useState(false);
	const [displayLoginError, setDisplayLoginError] = useState(false);
	const [displayPasswordError, setDisplayPasswordError] = useState(false);

	const classes = useLoginStyles();

	const handleChangeLogin = event => {
		setLogin(event.target.value);
		if (displayLoginError) {
			setDisplayLoginError(false);
		}
	};

	const handleChangePassword = event => {
		setPassword(event.target.value);
		if (displayPasswordError) {
			setDisplayPasswordError(false);
		}
	};

	const handleClickLogin = event => {
		event.preventDefault();
		setDisplayHelpMessage(false);
		if (login !== "" && password !== "") {
			onLogin();
		} else {
			if (login === "") {
				setDisplayLoginError(true);
			}
			if (password === "") {
				setDisplayPasswordError(true);
			}
		}
	};

	return (
		<form className={classes.bucketContent}>
			{errorMessage ? (
				<Alert type="error" message={errorMessage} icon={faTimesCircle} />
			) : null}
			<span className={classes.textFieldContainer}>
				<span className={classes.textField} data-testid="">
					<TextField
						label={"Username"}
						value={login}
						type={"email"}
						labelAlign={"right"}
						errorMessage={"Enter your username"}
						placeholder={"Required"}
						onChange={handleChangeLogin}
						error={displayLoginError}
						inputWidth={50}
					/>
				</span>
				<span className={classes.textField}>
					<TextField
						label={"Password"}
						value={password}
						type={"password"}
						labelAlign={"right"}
						errorMessage={"Enter your password"}
						placeholder={"Required"}
						onChange={handleChangePassword}
						error={displayPasswordError}
						inputWidth={50}
					/>
				</span>
			</span>
			<span className={classes.buttonContainer}>
				<button
					className={classes.loginButton}
					onClick={handleClickLogin}
					data-testid="login-button"
				>
					Log in
				</button>
				{!displayHelpMessage ? (
					<span
						className={classes.loginHelp}
						onClick={() => setDisplayHelpMessage(true)}
						data-testid="login-help"
					>
						Unable to log in or forgot your password?
					</span>
				) : (
					<Alert
						type="warning"
						message="Ask your teacher to reset your Username or Password."
						icon={faExclamationTriangle}
					/>
				)}
			</span>
		</form>
	);
};

export default Login;
