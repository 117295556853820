import React from "react";
import withStyles from "@mui/styles/withStyles";
import TopNavStyles from "./styles";
import MenuItem from "./components/MenuItem";
import PageMenuItem from "./components/PageMenuItem";
import UserMenuItem from "./components/UserMenuItem";
import useNavigation from "_hooks/useNavigation";
import { faTrophyAlt, faLightbulb } from "@fortawesome/pro-regular-svg-icons";
const styles = TopNavStyles;

const TopNav = ({
	districtName,
	initials,
	fullName,
	username,
	loggedIn,
	onLogout,
	classes
}) => {
	const { goLearn, goReview } = useNavigation();
	const topbar = loggedIn && (
		<span>
			<div className={`${classes.ersLeft} ${classes.ersTopBarSection}`}>
				<ul className={classes.ersMenu}>
					<PageMenuItem icon={faLightbulb} label={"Learn"} onClick={goLearn} />
					<PageMenuItem
						icon={faTrophyAlt}
						label={"Review"}
						onClick={goReview}
					/>
				</ul>
			</div>
			<div className={classes.ersRight}>
				<ul className={classes.ersMenu}>
					<UserMenuItem
						districtName={districtName}
						initials={initials}
						fullName={fullName}
						username={username}
						onLogout={onLogout}
					/>
				</ul>
			</div>
		</span>
	);

	return (
		<header>
			<div className={classes.ersContainer}>
				<div className={classes.ersBranding}>
					<ul className={classes.ersMenu}>
						<MenuItem
							customClass={classes.ersHome}
							body={<span className={classes.ersLogo}></span>}
							onClick={goLearn}
						/>
					</ul>
				</div>
				<div className={classes.ersTopBar}>{topbar}</div>
			</div>
			<div className={classes.ersAccent}></div>
		</header>
	);
};

export default withStyles(styles)(TopNav);
