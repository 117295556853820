import makeStyles from "@mui/styles/makeStyles";
import studentTheme from "themes/StudentTheme";

const useLearnAssignmentPrimerControlsStyles = makeStyles({
	controls: {
		fontFamily: studentTheme.fonts.primaryFont,
		width: "100%",
		height: "100%",
		justifyContent: "center"
	},
	button: {
		height: "3.25rem",
		width: "100%",
		justifyContent: "center",
		fontSize: "1.25rem",
		backgroundColor: studentTheme.colors.primaryColor
	},
	dueIcon: {
		paddingRight: "0.5rem"
	},
	dueOn: {
		fontSize: "1.25rem",
		fontFamily: studentTheme.fonts.primaryFont,
		color: studentTheme.colors.black,
		fontWeight: "bolder"
	},
	noDueOn: {
		fontSize: "1.25rem",
		fontFamily: studentTheme.fonts.primaryFont,
		color: studentTheme.colors.darkerGray
	},
	expand: {
		flexGrow: 10
	}
});

export default useLearnAssignmentPrimerControlsStyles;
