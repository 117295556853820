import React from "react";
import "css/style.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "@ellevation/backpack";
import { StudentRouter, AuthRouter } from "./routers";
import * as Sentry from "@sentry/react";
import TopNav from "components/TopNav";
import LandingPage from "pages/LandingPage";
import { errorRedirect } from "lib/api/helpers";
import { FlagProvider } from "@unleash/proxy-client-react";

const App = () => {
	return (
		<Sentry.ErrorBoundary fallback={errorRedirect}>
			<ThemeProvider>
				<FlagProvider
					config={{
						url: process.env.REACT_APP_UNLEASH_URL,
						clientKey: process.env.REACT_APP_UNLEASH_CLIENT_KEY,
						refreshInterval: 300,
						appName: "student-web"
					}}>
					<Router>
						<Switch>
							<Route path="/auth">
								<AuthRouter />
							</Route>
							<Route path="/:districtKey">
								<StudentRouter />
							</Route>
							<Route path="/">
								<TopNav loggedIn={false} />
								<LandingPage />
							</Route>
						</Switch>
					</Router>
				</FlagProvider>
			</ThemeProvider>
		</Sentry.ErrorBoundary>
	);
};

export default App;
