import { useState } from "react";
import {
	Dialog,
	DialogContent,
	DialogTitle,
	DialogActions,
	StandardDialogActions,
	Typography
} from "@ellevation/backpack";

export const ConfirmationDialog = ({ dialogOpen, setDialogOpen, onSubmit }) => {
	const [loading, setLoading] = useState(false);

	const onSubmitHandler = async () => {
		setLoading(true);
		await onSubmit();
		setLoading(false);
		setDialogOpen(false);
	};
	const onBackHandler = async () => {
		setDialogOpen(false);
	};

	return (
		<Dialog open={dialogOpen} onClose={onBackHandler} loading={loading}>
			<DialogTitle data-testid="dialogTitle">Submit Answers</DialogTitle>
			<DialogContent>
				<Typography sx={{ fontSize: "21px" }}>
					You've completed all the questions. Select{" "}
					<Typography
						sx={{ fontFamily: "bold", fontSize: "21px" }}
						component="span">
						Submit
					</Typography>{" "}
					to share your answers.
				</Typography>
			</DialogContent>
			<DialogActions>
				<StandardDialogActions
					primaryText={"Submit"}
					secondaryText={"Back"}
					primaryClickHandler={onSubmitHandler}
					secondaryClickHandler={onBackHandler}
				/>
			</DialogActions>
		</Dialog>
	);
};
