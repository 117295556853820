import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "components/Button";
import useNavigation from "_hooks/useNavigation";
import useLearnAssignmentPrimerControlsStyles from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/pro-regular-svg-icons";
import {
	learnNoDueDateMessage,
	learnStartPrimerMessage,
	learnContinuePrimerMessage
} from "copy/classroomsPage";
import studentTheme from "themes/StudentTheme";
import moment from "moment";

const LearnAssignmentPrimerControls = ({ assignment }) => {
	const classes = useLearnAssignmentPrimerControlsStyles();
	const { goPrimer } = useNavigation();
	const toPlayer = () => goPrimer(assignment.id);

	const dueDateComponent = assignment.dueOn ? (
		<Grid className={classes.dueOn}>
			<FontAwesomeIcon
				icon={faCalendarAlt}
				color={studentTheme.colors.black}
				className={classes.dueIcon}
			/>
			{`Due ${moment(assignment.dueOn).format("MMM D")}`}
		</Grid>
	) : (
		<div className={classes.noDueOn}>{learnNoDueDateMessage}</div>
	);

	return (
		<Grid item container direction="column" className={classes.controls}>
			<Box className={classes.expand}>{dueDateComponent}</Box>
			<Button
				buttonText={
					assignment.students[0].startedOn
						? learnContinuePrimerMessage
						: learnStartPrimerMessage
				}
				onClick={toPlayer}
				buttonClass={classes.button}
			/>
			<Box className={classes.expand}></Box>
		</Grid>
	);
};

export default LearnAssignmentPrimerControls;
