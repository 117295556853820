import React from "react";
import AssignmentCard from "../AssignmentCard";
import CardArt from "components/CardArt";
import useLearnAssignmentsSummaryStyles from "./styles";
import { learnPrimerMessage, learnMathTestMessage } from "copy/classroomsPage";
import moment from "moment";
import LearnAssignmentPrimerControls from "../LearnAssignmentPrimerControls";
import LearnAssignmentPostTestControls from "../LearnAssignmentPostTestControls";

const LearnAssignmentsSummary = ({ assignments }) => {
	const classes = useLearnAssignmentsSummaryStyles();
	const primerAssignments = assignments.filter(
		({ isCompleted }) => !isCompleted
	);
	const postTestAssignments = assignments.filter(
		({ isCompleted }) => isCompleted
	);

	return (
		<div>
			<h3 className={classes.title}>PRIMERS</h3>
			{primerAssignments.length === 0 ? (
				<div className={classes.noResults}>{learnPrimerMessage}</div>
			) : (
				primerAssignments.map(assignment => (
					<AssignmentCard
						key={assignment.id}
						title={assignment.title}
						dateString={`Assigned on ${moment(assignment.assignOn).format(
							"MMM D"
						)}`}
						art={<CardArt imageSrc={assignment.content.coverImageUrl} />}
						referenceLink={assignment.content.refSheetUrl}
						controls={<LearnAssignmentPrimerControls assignment={assignment} />}
					/>
				))
			)}
			<h3 className={classes.title}>MATH TESTS</h3>
			{postTestAssignments.length === 0 ? (
				<div className={classes.noResults}>{learnMathTestMessage}</div>
			) : (
				postTestAssignments.map(assignment => (
					<AssignmentCard
						key={assignment.id}
						title={assignment.title}
						art={<CardArt imageSrc={assignment.content.coverImageUrl} />}
						referenceLink={assignment.content.refSheetUrl}
						controls={
							<LearnAssignmentPostTestControls assignment={assignment} />
						}
					/>
				))
			)}
		</div>
	);
};

export default LearnAssignmentsSummary;
