import React from "react";
import withStyles from "@mui/styles/withStyles";
import TopNavStyles from "../../styles";

const styles = TopNavStyles;
/* eslint react/prop-types: 0 */

const MenuItem = ({ classes, customClass = "", body = null, onClick }) => {
	return (
		<li className={`${classes.ersMenuItem} ${customClass}`}>
			<a className={classes.ersMenuTarget} onClick={onClick}>
				{body}
			</a>
		</li>
	);
};

export default withStyles(styles)(MenuItem);
