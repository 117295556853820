import React, { useRef, useState } from "react";
import { Popover } from "@mui/material/";
import MenuItem from "../MenuItem";
import PopoverContent from "../PopoverContent";
import useUserMenuItemStyles from "./styles";

const UserMenuItem = ({
	districtName,
	initials,
	fullName,
	username,
	onLogout
}) => {
	const classes = useUserMenuItemStyles();
	const [isOpen, setIsOpen] = useState(false);
	const anchorRef = useRef();

	return (
		<MenuItem
			customClass={`${classes.ersUserMenu} ${
				isOpen ? classes.ersUserMenuHover : ""
			}`}
			body={
				<>
					<Popover
						open={isOpen}
						onClose={() => setIsOpen(false)}
						anchorEl={() => anchorRef.current}
						anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
						transformOrigin={{ vertical: "top", horizontal: "center" }}
						classes={{ paper: classes.popoverPaper }}
					>
						<PopoverContent
							classes={classes}
							initials={initials}
							fullName={fullName}
							username={username}
							onLogout={onLogout}
						/>
					</Popover>
					<span
						className={classes.ersMenuText}
						onClick={() => setIsOpen(true)}
						ref={anchorRef}
					>
						<span className={classes.ersUserInfo}>{districtName}</span>
						<span
							className={`${classes.ersUserAvatar} ${classes.ersMenuAvatar}`}
						>
							{initials}
						</span>
					</span>
				</>
			}
		/>
	);
};

export default UserMenuItem;
