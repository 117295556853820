import React from "react";
import Button from "components/Button";
import useNavigation from "_hooks/useNavigation";
import { errorMessage, buttonText } from "copy/errorPage";
import useErrorPageStyles from "./styles";
import detective from "img/error_page.png";
import { useHistory } from "react-router-dom";

const ErrorPage = () => {
	const classes = useErrorPageStyles();
	const { districtKey } = useNavigation();
	const { goBack } = useHistory();

	return (
		<div className={classes.root}>
			<div className={classes.content}>
				<img src={detective} className={classes.image} />
				<h3 className={classes.message}>{errorMessage}</h3>
				{districtKey !== "staff" && (
					<Button
						buttonText={buttonText}
						onClick={goBack}
						buttonClass={classes.button}
					/>
				)}
			</div>
		</div>
	);
};

export default ErrorPage;
