import { Box, Button, Typography } from "@ellevation/backpack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChevronRight,
	faPlay,
	faTrashAlt,
	faMicrophone,
	faStop
} from "@fortawesome/pro-solid-svg-icons";
import reading from "img/domain/Reading.svg";
import writing from "img/domain/Writing.svg";
import listening from "img/domain/Listening.svg";
import speaking from "img/domain/Speaking.svg";
import { TextToSpeech } from "../TextToSpeech";
import { usePoc } from "../../PocContextProvider";

export const Instructions = ({ name, domain, toggleIntro, questionCount }) => {
	const { registerPlayingAudio } = usePoc();

	const getDomainImage = domain => {
		switch (domain) {
			case "Reading":
				return reading;
			case "Writing":
				return writing;
			case "Listening":
				return listening;
			case "Speaking":
				return speaking;
		}
	};

	const getInstructions = domain => {
		switch (domain) {
			case "Reading":
				return (
					<>
						<TextToSpeech
							text="You will need to read passages to answer questions. You must
						complete all the questions. You can change your answers before
						submitting."
							id={0}
						/>
						You will need to read passages to answer questions. You must
						complete all the questions. You can change your answers before
						submitting.
					</>
				);
			case "Writing":
				return (
					<>
						<TextToSpeech
							text="You will use your writing skills to answer questions. You must
						complete all the questions. You can change your answers before
						submitting."
							id={0}
						/>
						You will use your writing skills to answer questions. You must
						complete all the questions. You can change your answers before
						submitting.
					</>
				);
			case "Listening":
				return (
					<>
						<TextToSpeech
							text="You will use your listening skills to answer questions. Select the
						play button to listen. You must complete all the questions. You can change your
						answers before submitting."
							id={0}
						/>
						You will use your listening skills to answer questions. Select the
						play button{" "}
						<FontAwesomeIcon
							icon={faPlay}
							style={{
								border: "0.5px solid",
								borderRadius: "50%",
								height: "12px",
								width: "12px",
								padding: "6px",
								verticalAlign: "middle"
							}}
						/>{" "}
						to listen. You must complete all the questions. You can change your
						answers before submitting.
					</>
				);
			case "Speaking":
				return (
					<>
						<TextToSpeech
							text="You will speak and record your answers to the questions. Select Microphone to record. Select Stop when you are ready to finish. Select Play to listen to your recording. Select Delete to delete your recording if needed."
							id={0}
						/>
						You will speak and record your answers to the questions.
						<Box sx={{ pl: "50px", pt: "20px" }}>
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<FontAwesomeIcon
									icon={faMicrophone}
									style={{
										border: "0.5px solid",
										borderRadius: "50%",
										height: "16px",
										width: "16px",
										padding: "4px",
										marginRight: "9px"
									}}
								/>
								<Box>
									Select{" "}
									<Typography
										component="span"
										sx={{ fontSize: "21px", fontFamily: "bold" }}>
										Microphone
									</Typography>{" "}
									to record
								</Box>
							</Box>
							<Box sx={{ pt: "16px", display: "flex", alignItems: "center" }}>
								<FontAwesomeIcon
									icon={faStop}
									style={{
										border: "0.5px solid",
										borderRadius: "50%",
										height: "14px",
										width: "14px",
										padding: "5px",
										marginRight: "9px"
									}}
								/>
								<Box>
									Select{" "}
									<Typography
										component="span"
										sx={{ fontSize: "21px", fontFamily: "bold" }}>
										Stop
									</Typography>{" "}
									when you are ready to finish
								</Box>
							</Box>
							<Box sx={{ pt: "16px", display: "flex", alignItems: "center" }}>
								<FontAwesomeIcon
									icon={faPlay}
									style={{
										border: "0.5px solid",
										borderRadius: "50%",
										height: "12px",
										width: "12px",
										padding: "6px",
										marginRight: "9px"
									}}
								/>
								<Box>
									Select{" "}
									<Typography
										component="span"
										sx={{ fontSize: "21px", fontFamily: "bold" }}>
										Play
									</Typography>{" "}
									to listen to your recording
								</Box>
							</Box>
							<Box sx={{ pt: "16px", display: "flex", alignItems: "center" }}>
								<FontAwesomeIcon
									icon={faTrashAlt}
									style={{
										border: "0.5px solid",
										borderRadius: "50%",
										height: "14px",
										width: "14px",
										padding: "5px",
										marginRight: "9px"
									}}
								/>
								<Box>
									Select{" "}
									<Typography
										component="span"
										sx={{ fontSize: "21px", fontFamily: "bold" }}>
										Delete
									</Typography>{" "}
									to delete your recording if needed
								</Box>
							</Box>
						</Box>
					</>
				);
		}
	};

	return (
		<Box
			sx={{
				backgroundColor: "#F9F9F9",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				height: "calc(100vh - 57px)"
			}}>
			<Box
				sx={{
					backgroundColor: "white",
					mt: "56px",
					p: "24px 40px",
					width: "50%",
					maxWidth: "711px"
				}}>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						pb: "20px",
						borderBottom: "1px solid #CCCCCC"
					}}>
					<img src={getDomainImage(domain)} />
					<Box sx={{ pl: "16px" }}>
						<Typography variant="h1">{domain}</Typography>
						<Typography sx={{ fontSize: "21px", color: "#666666" }}>
							{questionCount} questions
						</Typography>
					</Box>
				</Box>
				<Typography variant="h3" sx={{ pt: "28px" }}>
					Hi {name}!
				</Typography>
				<Typography sx={{ pt: "24px", pb: "32px", fontSize: "21px" }}>
					{getInstructions(domain)}
				</Typography>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center"
					}}>
					<Button
						sx={{ px: 6, py: 3, fontSize: 21 }}
						onClick={async () => {
							await registerPlayingAudio("tts", null);
							toggleIntro();
						}}>
						Start
						<FontAwesomeIcon
							icon={faChevronRight}
							style={{ paddingLeft: "8px", width: 16, height: 16 }}
						/>
					</Button>
				</Box>
			</Box>
		</Box>
	);
};
