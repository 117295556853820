import makeStyles from "@mui/styles/makeStyles";
import studentTheme from "themes/StudentTheme";

const useAssignmentCardStyles = makeStyles({
	root: {
		border: "0.0625rem",
		borderStyle: "solid",
		borderColor: studentTheme.colors.mediumGray,
		borderRadius: "0.3125rem",
		margin: "1.25rem 0",
		minHeight: "11.25rem",
		overflow: "hidden"
	},
	cardBody: {
		display: "flex"
	},
	controls: {
		backgroundColor: studentTheme.colors.whitesmokeGray,
		boxSizing: "border-box",
		padding: "1.25rem 1.25rem"
	},
	content: {
		marginLeft: "1.25rem"
	},
	titleContainer: {
		marginTop: "1.25rem",
		maxWidth: "90%"
	},
	title: {
		fontSize: "1.5rem",
		fontFamily: studentTheme.fonts.primaryFontSemibold
	},
	date: {
		fontSize: "1.125rem",
		fontFamily: studentTheme.fonts.primaryFont,
		color: studentTheme.colors.darkerGray,
		marginTop: "0.625rem",
		flexGrow: 10
	},
	refLink: {
		fontFamily: studentTheme.fonts.primaryFont,
		textDecoration: "none",
		fontSize: "1.125rem",
		color: studentTheme.colors.primaryColor,
		"&:hover": {
			textDecoration: "none"
		},
		marginBottom: "1.25rem"
	},
	refIcon: {
		paddingRight: "0.3125rem"
	},
	"@media only screen and (max-width : 600px)": {
		title: {
			fontSize: "1.25rem"
		}
	}
});

export default useAssignmentCardStyles;
