import React, { useRef } from "react";
import { Box, Typography } from "@ellevation/backpack";

export const QuestionContent = ({ supportingMaterial }) => {
	const audioRef = useRef(null);
	const videoRef = useRef(null);
	return (
		<Box sx={{ m: 2, mr: 16, width: "50%" }}>
			<Typography variant="h3">{supportingMaterial?.directions}</Typography>
			<Typography sx={{ mt: 2 }} variant="h4">
				{supportingMaterial?.title}
			</Typography>
			{supportingMaterial?.supportingContent?.text && (
				<Typography sx={{ mt: 3 }}>
					{supportingMaterial?.supportingContent?.text}
				</Typography>
			)}
			{supportingMaterial?.supportingContent?.image && (
				<img
					style={{ maxWidth: "100%", marginTop: "40px" }}
					src={supportingMaterial?.supportingContent?.image.src}
					alt={supportingMaterial?.supportingContent?.image.alt}
				/>
			)}
			{supportingMaterial?.supportingContent?.audio && (
				<Typography sx={{ mt: 3 }}>
					<audio
						data-testid="audio"
						ref={audioRef}
						src={supportingMaterial?.supportingContent?.audio}
						controls
					/>
				</Typography>
			)}
			{supportingMaterial?.supportingContent?.video && (
				<Typography sx={{ mt: 3 }}>
					<video
						data-testid="video"
						ref={videoRef}
						width="500"
						src={supportingMaterial?.supportingContent?.video}
						controls
					/>
				</Typography>
			)}
		</Box>
	);
};
