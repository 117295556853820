import React from "react";
import withStyles from "@mui/styles/withStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MenuItem from "../MenuItem";
import TopNavStyles from "../../styles";
const styles = TopNavStyles;

const PageMenuItem = ({ classes, icon, label, onClick }) => {
	const body = (
		<>
			<span className={classes.ersMenuIcon}>
				{icon && <FontAwesomeIcon icon={icon} />}
			</span>
			<span>{label}</span>
		</>
	);
	return (
		<MenuItem customClass={classes.ersMenuLink} body={body} onClick={onClick} />
	);
};

export default withStyles(styles)(PageMenuItem);
