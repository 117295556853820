import React from "react";
import useSSOLoginStyles from "./styles";

const SSOLogin = ({ providerName, providerUrl, logoUrl }) => {
	const classes = useSSOLoginStyles();

	return (
		<div className={classes.ssoContainer}>
			<div
				onClick={() => window.location.assign(providerUrl)}
				className={classes.ssoButton}
			>
				<img src={logoUrl} className={classes.ssoLogo} />
				<span className={classes.ssoText}>{`Log in with ${providerName}`}</span>
			</div>
		</div>
	);
};
export default SSOLogin;
