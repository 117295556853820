import makeStyles from "@mui/styles/makeStyles";
import studentTheme from "themes/StudentTheme";

const useLoginPageStyles = makeStyles(() => ({
	root: {
		backgroundColor: studentTheme.colors.whitesmokeGray,
		minHeight: `calc(100vh - 58px)`,
		display: "flex",
		flexDirection: "column"
	},
	container: {
		flex: 1,
		display: "flex",
		flexDirection: "column",
		paddingRight: "0.9375rem",
		paddingLeft: "0.9375rem"
	},
	box: {
		backgroundColor: studentTheme.colors.white,
		marginTop: "4.375rem",
		border: `1px solid ${studentTheme.colors.mediumGray}`
	},
	bucketHeader: {
		padding: "1.5rem 1.5rem 0.5rem 1.5rem"
	},
	header: {
		fontFamily: `${studentTheme.fonts.primaryFontSemibold}`,
		fontSize: "1.25rem",
		borderBottom: `1px solid ${studentTheme.colors.mediumGray}`,
		paddingBottom: "1rem"
	}
}));

export default useLoginPageStyles;
