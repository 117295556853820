import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Grid from "@mui/material/Grid";
import { faAngleDown, faAngleRight } from "@fortawesome/pro-regular-svg-icons";
import LearnAssignmentsSummary from "../LearnAssignmentsSummary";
import ReviewAssignmentsSummary from "../ReviewAssignmentsSummary";
import useClassroomAssignmentsContainerStyles from "./styles";
import { StatusCard } from "../StatusCard";
import { alphanumericSort, dateSort } from "lib/utils/sorting";

const getPageAssignments = (assignments, isLearnPage) => {
	if (isLearnPage) {
		return assignments
			.filter(
				({ isPostTestCompleted, isPastDue }) =>
					!isPostTestCompleted && !isPastDue
			)
			.sort(
				(a, b) =>
					dateSort("dueOn")(a, b) ||
					dateSort("assignOn")(a, b) ||
					alphanumericSort("title")(a, b)
			);
	}
	return assignments
		.filter(({ isCompleted, isPastDue }) => isCompleted || isPastDue)
		.sort(
			(a, b) =>
				dateSort("completedOn", { isAscending: false })(
					a.students[0],
					b.students[0]
				) ||
				dateSort("assignOn", { isAscending: false })(a, b) ||
				alphanumericSort("title")(a, b)
		);
};

const getAssignedCount = assignments =>
	assignments.filter(({ isCompleted, isPastDue }) => !isCompleted && !isPastDue)
		.length;

const getCompletedCount = assignments =>
	assignments.filter(({ isCompleted }) => isCompleted).length;

const ClassroomAssignmentsContainer = ({
	isLearnPage,
	classroom,
	assignments,
	isExpanded,
	onToggle
}) => {
	const classes = useClassroomAssignmentsContainerStyles();
	const icon = isExpanded ? faAngleDown : faAngleRight;
	const pageAssignments = getPageAssignments(assignments, isLearnPage);
	const assignedCount = getAssignedCount(assignments);
	const completedCount = getCompletedCount(assignments);

	return (
		<Grid item xs={12}>
			<div onClick={onToggle} className={classes.classroomTitleContainer}>
				<h2 className={classes.classroomTitle}>
					<span className={classes.expandIcon}>
						<FontAwesomeIcon icon={icon} />
					</span>
					<span>{classroom.title}</span>
				</h2>
			</div>
			{isExpanded && (
				<>
					<Grid
						container
						justifyContent="center"
						className={classes.statusContainer}
					>
						<Grid item xs={9}>
							<StatusCard
								isLearnPage={isLearnPage}
								assignedCount={assignedCount}
								completedCount={completedCount}
								classroomId={classroom.id}
							/>
						</Grid>
					</Grid>
					{pageAssignments.length > 0 && isLearnPage ? (
						<LearnAssignmentsSummary assignments={pageAssignments} />
					) : (
						<ReviewAssignmentsSummary assignments={pageAssignments} />
					)}
				</>
			)}
			<hr />
		</Grid>
	);
};

export default ClassroomAssignmentsContainer;
