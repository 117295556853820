import React, { useState, useEffect, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import {
	postAssignmentEvent,
	getStudentAssignment,
	getTextToSpeech
} from "lib/api/primerApi";
import { eventStatusConstants } from "_constants";
import {
	PrimerPlayerProvider,
	PrimerPlayer
} from "@ellevation/student-instruction-components";
import useNavigation from "_hooks/useNavigation";
import { exitDestinationConstants } from "_constants/primerPlayer";
import { submitAssignmentResponses } from "lib/api/primerApi";

const AssignmentPage = ({ exitDestination }) => {
	const params = useParams();
	const [assignment, setAssignment] = useState();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const key = searchParams.get("key");
	const source = searchParams.get("source");
	const assignmentComplete = useRef(false);
	const { goLearn, goReview } = useNavigation();

	const onDictionaryClick = (wordId, source) => {
		postAssignmentEvent(assignment.studentAssignmentId, {
			eventKey:
				source === eventStatusConstants.SOURCE_DICTIONARY
					? "word_click_dictionary"
					: "word_click_slide",
			wordId
		});
	};

	const onSlide = idx => {
		postAssignmentEvent(assignment.studentAssignmentId, {
			eventKey: "slide_view",
			slideIndex: idx,
			source
		});
	};

	const onComplete = () => {
		if (!assignmentComplete.current) {
			postAssignmentEvent(assignment.studentAssignmentId, {
				eventKey: "completed",
				source
			});
			assignmentComplete.current = true;
		}
	};

	const onPostTestComplete = () => {
		postAssignmentEvent(assignment.studentAssignmentId, {
			eventKey: "posttest_completed",
			source
		});
		goReview();
	};

	useEffect(() => {
		(async () => {
			const studentAssignment = await getStudentAssignment(params.id);
			if (!studentAssignment) {
				return;
			}
			setAssignment(studentAssignment);

			// if a completed event already exists, set that in state.
			assignmentComplete.current = !!studentAssignment.completedOn;
		})();
	}, [params.id]);

	const loadTts = async text => {
		return await getTextToSpeech(text);
	};

	return assignment ? (
		<PrimerPlayerProvider
			primerData={assignment}
			isStudent
			pageType={key}
			slideIndex={
				searchParams.get("slide") !== null
					? parseInt(searchParams.get("slide"), 10)
					: null
			}
			loadTextToSpeech={loadTts}
			submitAssignmentResponses={submitAssignmentResponses}>
			<PrimerPlayer
				onExit={
					exitDestination === exitDestinationConstants.REVIEW
						? goReview
						: goLearn
				}
				onOutroExit={goReview}
				onDictionaryClick={onDictionaryClick}
				onSlide={onSlide}
				onComplete={onComplete}
				onPostTestComplete={onPostTestComplete}
			/>
		</PrimerPlayerProvider>
	) : null;
};

export default AssignmentPage;
