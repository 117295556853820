import React from "react";
import useTextFieldStyles from "./styles";

const TextField = ({
	label,
	value,
	type,
	labelAlign,
	placeholder,
	error,
	errorMessage,
	onChange,
	inputWidth = 100
}) => {
	const classes = useTextFieldStyles({ inputWidth: inputWidth });

	let labelAlignStyle = labelAlign === "right" ? classes.rightAlignLabel : "";

	return (
        <div>
			<div className={classes.inputContainer}>
				<label className={`${classes.label} ${labelAlignStyle}`}>{label}</label>
				<input
					type={type}
					className={`${classes.textField} ${
						error ? classes.errorTextField : classes.basicTextField
					}`}
					placeholder={placeholder}
					value={value}
					onChange={onChange}
					data-testid={`${label.replace(/\s/g, "-").toLowerCase()}-input`}
				/>
			</div>
			{error ? (
				<div
					className={classes.textFieldMessage}
					data-testid={`${label.replace(/\s/g, "-").toLowerCase()}-error`}
				>
					{errorMessage}
				</div>
			) : null}
		</div>
    );
};

export default TextField;
