import React from "react";
import useAssignmentSummaryStyles from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-regular-svg-icons";

const validScores = (numerator, denominator) => {
	const validDenominator = !isNaN(parseInt(denominator, 10))
		? parseInt(denominator, 10)
		: false;
	const validNumerator = !isNaN(parseInt(numerator, 10)) ? true : false;
	return validNumerator && validDenominator && validDenominator > 0;
};

const linkToPlayer = (toPlayer, isDisabled) => {
	if (!isDisabled) {
		toPlayer();
	}
};

const AssignmentSummary = ({
	isPrimer = true,
	isComplete = false,
	numerator,
	denominator,
	isDisabled = false,
	toPlayer
}) => {
	const classes = useAssignmentSummaryStyles();
	const header = isPrimer ? "VOCAB TEST" : "MATH TEST";
	let linkText = "";
	if (isPrimer) {
		linkText = "View Primer";
	} else {
		linkText = isComplete ? "View Results" : "Start";
	}
	const disabledClass = isDisabled ? classes.disabled : "";

	const isValid = isComplete && validScores(numerator, denominator);
	const score = isComplete && isValid ? numerator + " / " + denominator : "";
	const scoreClass =
		isComplete &&
		isValid &&
		parseInt(numerator, 10) / parseInt(denominator, 10) > 0.5
			? classes.greenScore
			: classes.grayScore;

	return (
		<div className={`${classes.root} ${disabledClass}`}>
			<span className={classes.header}>{header}</span>
			{!isComplete && (
				<div className={classes.incomplete}>
					<span className={classes.exclamationIcon}>
						<FontAwesomeIcon icon={faExclamationCircle} size={"2x"} />
					</span>
					{"INCOMPLETE"}
				</div>
			)}
			{isComplete && (
				<div className={`${classes.complete}  ${scoreClass}`}>{score}</div>
			)}
			<div
				className={classes.linkText}
				onClick={() => linkToPlayer(toPlayer, isDisabled)}>
				{linkText}
			</div>
		</div>
	);
};

export default AssignmentSummary;
