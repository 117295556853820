import makeStyles from "@mui/styles/makeStyles";
import studentTheme from "themes/StudentTheme";

const useAssignmentSummaryStyles = makeStyles({
	root: {
		border: "0.0625rem",
		borderStyle: "solid",
		borderColor: studentTheme.colors.mediumGray,
		backgroundColor: studentTheme.colors.white,
		borderRadius: "0.3125rem",
		overflow: "hidden",
		fontSize: "0.875rem",
		height: "6.25rem",
		width: "6.875rem",
		display: "flex",
		flexDirection: "column",
		alignItems: "center"
	},
	disabled: {
		opacity: "50%"
	},
	header: {
		fontFamily: studentTheme.fonts.primaryFontSemibold,
		paddingTop: "0.9375rem"
	},
	pretextIcon: {
		paddingRight: "0.3125rem"
	},
	complete: {
		fontSize: "1.875rem",
		fontFamily: studentTheme.fonts.primaryFontSemibold,
		padding: "0.53125rem"
	},
	greenScore: {
		color: studentTheme.colors.successColor
	},
	grayScore: {
		color: studentTheme.colors.darkerGray
	},
	incomplete: {
		fontSize: "0.625rem",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		color: studentTheme.colors.silverGray,
		padding: "0.375rem"
	},
	exclamationIcon: {
		color: studentTheme.colors.warningColor,
		paddingBottom: "0.3125rem"
	},
	linkText: {
		color: studentTheme.colors.primaryColor,
		cursor: "pointer"
	},
	"@media only screen and (max-width : 1168px)": {
		root: {
			width: "100%"
		}
	}
});

export default useAssignmentSummaryStyles;
