import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router";
import { Amplify } from "aws-amplify";
import { sessionStorage } from "aws-amplify/utils";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import CustomAuthLoadingPage from "pages/CustomAuthLoadingPage";
import ErrorPage from "pages/ErrorPage";

const setupAuth = () => {
	// globally configure AWS cognito
	const authConfig = {
		Cognito: {
			userPoolId: process.env.REACT_APP_USER_POOL_ID,
			userPoolClientId: process.env.REACT_APP_WEB_CLIENT_ID
		}
	};
	Amplify.configure({
		Auth: authConfig
	});
	cognitoUserPoolsTokenProvider.setKeyValueStorage(sessionStorage);
};

const AuthRouter = () => {
	useEffect(() => setupAuth(), []);
	return (
		<Switch>
			<Route path="/auth/assertion">
				<CustomAuthLoadingPage />
			</Route>
			<Route path="/auth/error">
				<ErrorPage />
			</Route>
			<Route path="/auth">
				<Redirect to="/" />
			</Route>
		</Switch>
	);
};

export default AuthRouter;
