import React, { useState } from "react";
import {
	Box,
	Button,
	FormControl,
	FormHelperText,
	FormLabel,
	TextInput,
	Typography
} from "@ellevation/backpack/lib/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import reading from "img/domain/Reading.svg";
import writing from "img/domain/Writing.svg";
import listening from "img/domain/Listening.svg";
import speaking from "img/domain/Speaking.svg";
import loginBackground from "img/PocLogin.svg";

export const StudentLogin = ({ sendCode, error, domain }) => {
	const [codeInput, setCodeInput] = useState("");

	const getDomainImage = domain => {
		switch (domain) {
			case "Reading":
				return reading;
			case "Writing":
				return writing;
			case "Listening":
				return listening;
			case "Speaking":
				return speaking;
		}
	};

	const handleChange = e => {
		setCodeInput(e.target.value);
	};

	const handleCodeInput = async () => {
		sendCode(codeInput);
	};

	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				flexDirection: "column",
				height: "calc(100vh - 57px)",
				backgroundImage: `url(${loginBackground})`,
				backgroundSize: "cover",
				backgroundPosition: "right bottom",
				backgroundRepeat: "no-repeat"
			}}>
			<Box
				sx={{
					backgroundColor: "white",
					mt: "101px",
					p: "24px 40px 32px 40px",
					width: "40%",
					maxWidth: "584px",
					border: "1px solid #CCCCCC",
					borderRadius: "4px"
				}}>
				{domain && (
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							pb: "20px",
							borderBottom: "1px solid #CCCCCC"
						}}>
						<img src={getDomainImage(domain)} />
						<Box sx={{ pl: "16px" }}>
							<Typography variant="h1">{domain}</Typography>
						</Box>
					</Box>
				)}
				<Box sx={{ mt: "24px" }}>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "column"
						}}>
						<FormControl fullWidth>
							<FormLabel
								sx={{ fontSize: "21px", color: "#333333", pb: "12px" }}>
								Enter code to log in
							</FormLabel>
							<TextInput
								placeholder="Code"
								onChange={handleChange}
								value={codeInput}
								fullWidth
								error={error}
								InputProps={{
									style: { fontSize: "21px" }
								}}
							/>
							{error && (
								<FormHelperText error={error} sx={{ fontSize: "21px" }}>
									{error}
								</FormHelperText>
							)}
						</FormControl>
						<Button
							sx={{ mt: "32px", px: "27px", py: 3, fontSize: "20px" }}
							onClick={handleCodeInput}>
							Log in
							<FontAwesomeIcon
								icon={faChevronRight}
								style={{ paddingLeft: "8px", width: 16, height: 16 }}
							/>
						</Button>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};
