import makeStyles from "@mui/styles/makeStyles";
import studentTheme from "themes/StudentTheme";

const useSSOLoginStyles = makeStyles(() => ({
	ssoContainer: {
		height: "6.875rem",
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	},
	ssoButton: {
		padding: "0.5rem",
		width: "17.5rem",
		border: `1px solid ${studentTheme.colors.mediumGray}`,
		borderRadius: "0.25rem",
		display: "flex",
		alignItems: "center",
		cursor: "pointer"
	},
	ssoLogo: {
		maxHeight: "1.875rem",
		padding: "0.5rem",
		borderRight: `1px solid ${studentTheme.colors.mediumGray}`
	},
	ssoText: {
		padding: "0.5rem"
	}
}));
export default useSSOLoginStyles;
