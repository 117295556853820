import React, { useState } from "react";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import Alert from "components/Alert";
import TextField from "components/TextField";
import useStyles from "./styles";

const ResetPassword = ({ errorType, onUpdate }) => {
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [passwordError, setPasswordError] = useState(false);
	const [confirmPasswordError, setConfirmPasswordError] = useState(false);
	const [justSubmitted, setJustSubmitted] = useState(true);

	const classes = useStyles();

	const handleChangePassword = event => {
		setPassword(event.target.value);
		setPasswordError(false);
		setJustSubmitted(false);
	};

	const handleChangeConfirmPassword = event => {
		setConfirmPassword(event.target.value);
		confirmPasswordError && setConfirmPasswordError(false);
	};

	const handleClickUpdate = async event => {
		event.preventDefault();
		if (password === "") {
			setPasswordError(true);
			return;
		}
		if (password !== confirmPassword) {
			setConfirmPasswordError(true);
			return;
		}
		await onUpdate(password);
		setJustSubmitted(true);
	};

	return (
		<form className={classes.bucketContent}>
			<p className={classes.introText}>Please choose a new password. </p>
			<Alert
				type="info"
				icon={faInfoCircle}
				message="Your password must be at least six characters long and contain at least one number and one lower case letter."
			/>
			<span className={classes.textFieldContainer}>
				<span className={classes.textField}>
					<TextField
						label="New Password"
						value={password}
						type="password"
						labelAlign="right"
						errorMessage="Your password is invalid."
						placeholder="Required"
						onChange={handleChangePassword}
						error={
							passwordError ||
							(justSubmitted && errorType === "InvalidPasswordException")
						}
						inputWidth={50}
					/>
				</span>
				<span className={classes.textField}>
					<TextField
						label="Confirm Password"
						value={confirmPassword}
						type="password"
						labelAlign="right"
						errorMessage="This does not match the password field above."
						placeholder="Required"
						onChange={handleChangeConfirmPassword}
						error={confirmPasswordError}
						inputWidth={50}
					/>
				</span>
			</span>
			<span className={classes.buttonContainer}>
				<button
					className={classes.updateButton}
					onClick={handleClickUpdate}
					data-testid="update-button"
				>
					Update Password
				</button>
			</span>
		</form>
	);
};

export default ResetPassword;
