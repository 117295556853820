import makeStyles from "@mui/styles/makeStyles";
import studentTheme from "themes/StudentTheme";
import banner from "img/Banner.png";

const useStyles = makeStyles({
	banner: {
		borderBottom: `1px solid ${studentTheme.colors.mediumGray}`
	},
	bannerImage: {
		backgroundImage: `url(${banner})`,
		height: "158px",
		backgroundRepeat: "repeat-x"
	},
	bannerContent: {
		margin: "-84px auto 1.875rem auto",
		padding: "0 1.875rem",
		display: "flex",
		flexDirection: "column",
		alignItems: "stretch",
		maxWidth: "75rem"
	},
	welcomeContainer: {
		display: "flex",
		alignItems: "flex-end",
		flexWrap: "wrap"
	},
	character: {
		marginRight: "1.1875rem",
		height: "168px"
	},
	welcomeText: {
		fontFamily: studentTheme.fonts.primaryFontSemibold,
		fontSize: "2.5rem",
		margin: "1.125rem 0"
	},
	description: {
		marginTop: "1.375rem",
		fontSize: "1.5rem"
	}
});

export default useStyles;
