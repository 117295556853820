import moment from "moment";

export const initialState = {
	classrooms: [],
	expanded: {},
	data: {},
	details: {},
	assignmentData: {}
};

export const actionTypes = {
	SET_CLASSROOMS: "SET_CLASSROOMS",
	TOGGLE_CLASSROOM: "TOGGLE_CLASSROOM",
	SET_ACTIVE_PRIMERS: "SET_ACTIVE_PRIMERS",
	SET_CLASSROOM_PRIMERS: "SET_CLASSROOM_PRIMERS",
	SET_CLASSROOM_POST_TESTS: "SET_CLASSROOM_POST_TESTS",
	SET_PRIMER_DETAILS: "SET_PRIMER_DETAILS",
	SET_ASSIGNMENTS: "SET_ASSIGNMENTS"
};

const setInitExpandedState = (action, expandedClassroomId) => {
	let expanded = {};
	action.data.forEach(classroom => {
		expanded[classroom.id] = classroom.id === expandedClassroomId;
	});
	return expanded;
};

const mapAssignments = assignments =>
	assignments.map(assignment => {
		const title = assignment.content ? assignment.content.title : "";
		const students = assignment.students.map(student => ({
			...student
		}));
		// don't mark as past due until the end of the day
		const isPastDue =
			assignment.dueOn != null &&
			moment(assignment.dueOn).add(1, "days") < moment();
		const isCompleted = !!students[0].completedOn;
		const isPostTestCompleted =
			isCompleted && !!students[0].posttestCompletedOn;
		return {
			...assignment,
			students,
			title,
			isPastDue,
			isCompleted,
			isPostTestCompleted
		};
	});

export const reducer = (state, action) => {
	switch (action.type) {
		case actionTypes.SET_CLASSROOMS:
			return {
				...state,
				classrooms: action.data,
				expanded: setInitExpandedState(action, action.expandedClassroomId)
			};
		case actionTypes.TOGGLE_CLASSROOM:
			return {
				...state,
				expanded: {
					...state.expanded,
					[action.classroomId]: !state.expanded[action.classroomId]
				}
			};
		case actionTypes.SET_CLASSROOM_PRIMERS:
			return {
				...state,
				data: {
					...state.data,
					[action.classroomId]: {
						...state.data[action.classroomId],
						primers: action.data
					}
				}
			};
		case actionTypes.SET_CLASSROOM_POST_TESTS:
			return {
				...state,
				data: {
					...state.data,
					[action.classroomId]: {
						...state.data[action.classroomId],
						postTests: action.data
					}
				}
			};
		case actionTypes.SET_ACTIVE_PRIMERS:
			return {
				...state,
				data: {
					...state.data,
					[action.classroomId]: {
						...state.data[action.classroomId],
						activePrimers: action.data
					}
				}
			};
		case actionTypes.SET_PRIMER_DETAILS:
			return {
				...state,
				details: {
					...state.details,
					[action.classroomId]: {
						...state.details[action.classroomId],
						[action.primerId]: action.data
					}
				}
			};
		case actionTypes.SET_ASSIGNMENTS:
			return {
				...state,
				assignmentData: {
					...state.assignmentData,
					[action.classroomId]: mapAssignments(action.assignments)
				}
			};
		default:
			return state;
	}
};
