import { useEffect } from "react";
import { signIn, confirmSignIn, fetchUserAttributes } from "aws-amplify/auth";
import { useHistory, useLocation } from "react-router";
import { getLoginInfo, postUserActivity } from "lib/api/primerApi";
import { sessionStorageConstants } from "_constants";

const handleCustomAuth = async (assertionToken, history) => {
	const result = await getLoginInfo(assertionToken);
	if (!result) {
		return;
	}
	try {
		const { nextStep } = await signIn({
			username: result.username,
			options: {
				authFlowType: "CUSTOM_WITHOUT_SRP"
			}
		});
		if (nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE") {
			await confirmSignIn({ challengeResponse: assertionToken });
		} else {
			console.log("Error: Unknown challenge");
			history.replace("/auth/error");
			return;
		}

		const attributes = await fetchUserAttributes();

		postUserActivity({
			eventKey: "student_login",
			userId: Number(attributes["custom:student_id"])
		});

		const customDestination = window.sessionStorage.getItem(
			sessionStorageConstants.SSO_DESTINATION_KEY
		);
		history.replace(
			customDestination ? customDestination : `/${result.key}/learn`
		);
	} catch (err) {
		console.log(err);
		history.replace("/auth/error");
	}
};
const CustomAuthLoadingPage = () => {
	const history = useHistory();
	const location = useLocation();

	useEffect(() => {
		const handler = async () => await handleCustomAuth(assertionToken, history);
		const assertionToken = new URLSearchParams(location.search).get("token");
		if (assertionToken) {
			handler();
		} else {
			history.replace("/");
		}
	}, []);
	return "";
};
export default CustomAuthLoadingPage;
